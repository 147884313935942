import React from 'react';
import { LoadingAnimation, LoadingAnimationFrame } from './styled';

interface LoadingProps {
  fullscreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ fullscreen }) => {
  return (
    <LoadingAnimationFrame isFullscreen={fullscreen}>
      <LoadingAnimation />
    </LoadingAnimationFrame>
  );
};

export default Loading;
