export const iconFilesMap = {
  larrow: 'larr-stroke-blue',
  darrowCream: 'darr-stroke-cream',
  check: 'check-stroke-navy',
  checkPositive: 'check-stroke-green',
} as const;

export type AvailableIconsMap = typeof iconFilesMap;
export type IconFileNames = typeof iconFilesMap[keyof typeof iconFilesMap] | '';

export const getIconFilename = (
  icon: keyof AvailableIconsMap,
): IconFileNames =>
  icon in iconFilesMap ? iconFilesMap[icon] : '';
