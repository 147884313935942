import React from 'react';
import * as S from './styled';

interface NumberCircleProps {
  children: React.ReactNode;
}

const NumberCircle: React.FC<NumberCircleProps> = ({ children }) => (
  <S.Circle>{children}</S.Circle>
);

export default NumberCircle;
