import Button from 'components/Button';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { css, styled } from 'styled';
import { srem, t, mq } from 'styled/utils';
import { keyframes } from 'styled-components';

const linkStyles = css`
  display: flex;
  text-decoration: none;
  align-items: center;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  * + & {
    margin-left: ${srem(4)};
  }

  /* 
   * This active class is added by react-router-dom's NavLink based on the route
   */
  &.active {
    border-bottom-color: currentColor;
  }
`;

export const NavFrame = styled.header<{
  withBackground?: boolean;
  isFixed?: boolean;
  withOffset?: string;
  isLight?: boolean;
}>`
  ${({ withOffset, isFixed, theme: t }) =>
    isFixed &&
    `
    position: fixed;
    top: ${withOffset || '0'};
    left: 0;
    right: 0;
    z-index: ${t.stack.nav};
    transition: background 500ms ease;
  `}

  width: 100vw;
  display: flex;
  padding: ${srem(2)};
  background: ${({ isLight, theme: t, withBackground }) =>
    withBackground ? (isLight ? t.colors.blue : 'white') : 'transparent'};

  ${mq('tablet')} {
    padding: ${srem(3)} ${srem(6)};
  }
`;

export const NavLink = styled(RouterNavLink)`
  ${linkStyles}
`;

export const NavAnchor = styled.a`
  ${linkStyles}
`;

export const NavButton = styled(Button)``;

export const NavLogo = styled(Link)<{ isLight?: boolean }>`
  display: block;
  background: no-repeat
    url(${({ isLight }) => `/frontier-logo${isLight ? '-cream' : ''}.svg`})
    center / 100% auto;
  width: 120px;
  /* h=w*0.148471616 */
  height: ${190 * 0.148471616}px;

  ${mq('tablet')} {
    width: 190px;
  }
`;

export const NavLinks = styled.nav<{ isLight?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-left: auto;

  ${({ isLight, theme }) =>
    isLight &&
    `
  ${NavLink}, ${NavAnchor} {
    color: ${theme.colors.cream};
  }
  
  ${NavButton} {
    background: ${theme.colors.cream};
    color: ${theme.colors.black};
  }
  `}
`;

/**
 * The mobile nav overlay
 */
const animateInOverlay = keyframes`
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
`;
export const animateInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
`;

const delayAnimation = (n: number, ms: number = 100) => {
  let strs = [];
  for (let i = 1; i < n + 1; i++) {
    strs.push(`
      &:nth-child(${i}) {
        animation-delay: ${ms * i}ms;
      }
    `);
  }
  return strs.join('\n');
};

export const MobileNavOverlay = styled.section`
  position: fixed;
  z-index: ${t(t => t.stack.nav)};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${t(t => t.colors.blue)};
  color: ${t(t => t.colors.cream)};
  padding: ${srem(2)};
  animation: ${animateInOverlay} 300ms 1 both ${t(t => t.easing.easeOutExpo)};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const MobileNavLinks = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const mobileNavLink = css`
  position: relative;
  color: ${t(t => t.colors.cream)};
  text-decoration: none;
  font: ${srem(5)} ${t(t => t.fonts.headings.family)};
  margin: 0.5em 0;
  animation: ${animateInFromTop} 400ms 1 both ${t(t => t.easing.easeOutExpo)};

  ${delayAnimation(5)}

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${t(t => t.colors.cream)};
  }
`;

export const MobileNavLink = styled(RouterNavLink)`
  ${mobileNavLink}
`;

export const MobileNavAnchor = styled.a`
  ${mobileNavLink}
`;

export const MobileNavHeader = styled.header`
  position: absolute;
  top: ${srem(2)};
  right: ${srem(2)};
  left: ${srem(2)};
`;

export const MobileNavFooter = styled.footer`
  animation: ${animateInFromTop} 1200ms 600ms 1 both
    ${t(t => t.easing.easeOutExpo)};
  position: absolute;
  right: ${srem(2)};
  bottom: ${srem(12)};
  left: ${srem(2)};
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    display: block;
    color: ${t(t => t.colors.cream)};
    text-decoration: none;
    font-size: ${srem(1)};
  }
`;
