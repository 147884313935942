import { darken } from 'polished';
import { styled } from 'styled';
import { ift, ms, t } from 'styled/utils';

interface StyledButtonProps {
  isFlat?: boolean;
  flexGrow?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isSecondary?: boolean;
  isCaps?: boolean;
}

export const ButtonFrame = styled.div<StyledButtonProps>`
  border-radius: ${t(t => t.radius.button)}px;
  border: ${ift('isSecondary', t => `2px solid ${t.colors.blue}`, 'none')};
  background-color: ${ift('isSecondary', 'transparent', t => t.colors.blue)};
  color: ${ift(
    'isSecondary',
    t => t.colors.blue,
    t => t.colors.cream,
  )};
  padding: ${ms(-3)}em ${ms(1)}em;
  text-decoration: none;
  font-size: ${({ isLarge, isSmall, theme: t }) =>
    isLarge ? t.msrem(2) : isSmall ? t.msrem(-1) : t.msrem(1)};
  font-weight: ${t(t => t.fonts.alpha.weights.medium)};
  text-align: center;
  transition: all 600ms ${t(t => t.easing.easeOutExpo)};
  cursor: pointer;
  box-shadow: ${ift('isFlat', 'none', t => t.shadows.close.subtleGray)};
  /* Positioning props */
  flex-grow: ${ift('flexGrow', () => '1', '0')};
  text-transform: ${ift('isCaps', 'uppercase', 'none')};

  &:hover {
    background: ${ift(
      'isSecondary',
      t => t.colors.blue,
      t => darken(0.1, t.colors.blue),
    )};
    color: ${t(t => t.colors.cream)};
    transition: all 300ms ${t(t => t.easing.easeOutExpo)};
  }
`;
