import EmailSignup from 'components/EmailSignup';
import { Section } from 'components/Page';
import { Title } from 'components/Styled';
import { css, styled } from 'styled';
import {
  boxRatio,
  ift,
  srem,
  t,
  mq,
  device,
  paddingUntilWidth,
} from 'styled/utils';
import { getIconFilename } from 'utils/icons';
import { keyframes } from 'styled-components';

const alignCenter = css`
  width: 100%;
  max-width: ${t(t => t.size.maxWidth)}px;
  margin: 0 auto;
`;

/**
 * Hero / Opener section
 */
export const Hero = styled.header`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  min-height: 86vh;

  ${mq('laptop')} {
    flex-flow: row nowrap;
    height: 90vh;
  }
`;

export const HeroText = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  text-align: center;
  padding: 0 ${srem(2)} ${srem(2)};
  max-width: 500px;
  margin: -3vh auto 0;

  ${mq('laptop')} {
    margin: 0;
    max-width: 100%;
    width: 50vw;
    text-align: left;
    flex-flow: row nowrap;
    height: 100%;
    padding: 0 0 0 ${srem(3)};
  }
`;

export const HeroEmailSignup = styled(EmailSignup)`
  margin-top: ${srem(2)};

  ${mq('laptop')} {
    margin-top: ${srem(3)};
    padding-right: ${srem(8)};
  }
`;

export const HeroHalf = styled.div`
  width: 100%;
  max-width: ${t(t => t.size.maxWidth / 2)}px;
`;

export const HeroP = styled.p`
  width: 100%;
  font-size: 18px;

  ${mq('laptop')} {
    max-width: 528px;
    margin: ${srem(4)} 0;
    font-size: ${srem(1)};
  }
`;

export const HeroImage = styled.div`
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 51vh;
  margin-top: 18vh;

  ${mq('laptop')} {
    position: relative;
    margin: 0;
    justify-content: flex-start;
    width: 50vw;
    height: 100%;
    background: ${t(t => t.colors.cream)};
    padding-top: ${srem(12)};
  }
`;

type HeroImgProps = {
  withImg: string;
  topBarOffset?: boolean;
};
export const HeroImageIlly = styled.div<HeroImgProps>`
  background: no-repeat url(${({ withImg }) => withImg}) center / auto 100%;
  display: block;
  width: 100%;

  transition: all 300px ease;

  @media screen and (${device('tablet')}) and (max-height: 800px) {
    background-size: auto 120%;
  }
  @media screen and (${device('tablet')}) and (max-height: 700px) {
    background-size: auto 150%;
  }

  ${mq('laptop')} {
    background-position: top left;
    position: absolute;
    top: ${({ topBarOffset }) => (topBarOffset ? '140px' : '80px')};
    bottom: 0;
    left: -24px;
    right: 0;
  }
`;

/**
 * Mobile hero
 */
export const HeroMblTitle = styled.h1`
  position: absolute;
  top: 13vh;
  left: 0;
  text-align: center;
  width: 100vw;
  font: ${srem(3)} ${({ theme: t }) => t.fonts.headings.family};
  color: ${t(t => t.colors.black)};
`;

/**
 * Companies section
 */
const pulseAnimation = keyframes` 50% { opacity: .4; } `;
const arrowAnimation = keyframes`
  0% {
    transform: scaleY(1);
  }

  10% {
    transform: scaleY(1.3);
  }

  15% {
    transform: scaleY(1);
  }
`;

export const CompaniesSection = styled(Section)`
  z-index: 100;
  padding-bottom: ${srem(7)};

  ${mq('tablet')} {
    padding-bottom: ${srem(10)};
  }
`;

export const CompanaiesHeader = styled.header`
  width: 100%;
  color: ${t(t => t.colors.cream)};
  text-align: center;

  &::before {
    transform-origin: top;
    animation: 
      ${arrowAnimation} 5s 2s infinite both ${t(t => t.easing.easeOutExpo)};
      /* ${pulseAnimation} 2s infinite both ease; */
    content: '';
    display: block;
    margin: 0 auto;
    width: 18px;
    height: 24px;
    background: no-repeat url('/icons/${getIconFilename('darrowCream')}.svg')
      center / 100% 100%;
    margin-bottom: ${srem(5)};

    ${mq('tablet')} {
      margin-bottom: ${srem(8)};
    }
  }
`;
export const CompaniesLogosGrid = styled.ul`
  display: grid;
  width: 100%;
  max-width: ${t(t => t.size.maxWidth * (10 / 12))}px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 55px;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  justify-items: stretch;
  gap: ${srem(6)};
  margin: ${srem(5)} auto 0;

  ${mq('laptop')} {
    margin-top: ${srem(7)};
    grid-auto-rows: 100px;
  }
`;

type CompanyLogoStyledProps = {
  withImg: string;
};
export const CompanyLogo = styled.li<CompanyLogoStyledProps>`
  display: block;
  width: 100%;
  background: 
    no-repeat 
    url('${({ withImg }) => `/logos/${withImg}`}') 
    center / contain;

  ${mq('tablet')} {
    background-size: auto;
  }
`;

/**
 * Process section
 */
export const ProcessSection = styled(Section)`
  ${({ theme: t }) => paddingUntilWidth(t.size.maxWidth)};
`;

export const ProcessGrid = styled.div`
  width: 100%;

  ${mq('tablet')} {
    display: grid;
    grid-template:
      'title desc desc' auto
      'a b c' 1fr / 1fr 1fr 1fr;
    gap: ${srem(10)} ${srem(4)};
  }

  ${alignCenter}
`;

export const ProcessTitle = styled(Title)`
  width: 100%;
  text-align: center;

  ${mq('tablet')} {
    text-align: left;
    width: auto;
    grid-area: title;
  }
`;

export const ProcessDesc = styled.p`
  width: 100%;
  text-align: center;
  max-width: 500px;
  margin: ${srem(2)} auto 0;
  margin-bottom: ${srem(5)};

  ${mq('tablet')} {
    text-align: left;
    /* max-width: 100%; */
    margin: 0;
    width: auto;
    grid-area: desc;
    font-size: ${srem(2)};
  }
`;

export const ProcessTile = styled.div<{ withPart: string }>`
  margin-top: ${srem(6)};

  ${mq('tablet')} {
    margin-top: 0;
    max-width: 416px;
    width: 100%;
    grid-area: ${({ withPart }) => withPart};
  }
`;

export const ProcessTileText = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  p {
    margin-top: ${srem(2)};
    font-size: ${srem(1)};
  }

  ${mq('tablet')} {
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: ${srem(3)};
    height: 60px;

    p {
      width: 100%;
      max-width: 360px;
      margin: 0 0 0 1rem;
    }
  }
`;

export const ProcessImg = styled.div<{ withPart: string }>`
  background: 
    no-repeat 
    url('/illys/process-mob-${({ withPart }) => withPart}.png') 
    center / 100% auto;
  margin: ${srem(4)} auto 0;
  width: 100%;

  /* on mobile all images have different h:w ratios */
  ${({ withPart }) => {
    switch (withPart) {
      case 'a':
        return `
          max-width: 478px;
          ${boxRatio(1.141213389)}
        `;
      case 'b':
        return `
          max-width: 663px;
          ${boxRatio(0.858974359)}
        `;
      case 'c':
        return `
          max-width: 696px; 
          ${boxRatio(0.673850575)}
        `;
    }
  }}

  ${mq('tablet')} {
    margin-top: 0;
    max-width: 100%;
    width: auto;
    background: 
      no-repeat 
      url('/illys/process-${({ withPart }) => withPart}.svg') 
      center / 100% auto;
    ${boxRatio(1.634615385)}
  }

`;

/**
 * Guide section
 */
export const GuideSection = styled(Section)``;

export const GuideHeader = styled.header`
  ${alignCenter}
  text-align: left;
  padding-left: ${t(t => t.size.maxWidth / 12)}px;
  margin-bottom: ${srem(10)};
`;

export const GuideParts = styled.article`
  ${alignCenter};
`;

export const GuidePartText = styled.div`
  width: 30vw;

  p {
    max-width: 304px;
    margin-top: 1rem;
    font-size: ${srem(2)};
  }
`;

export const GuidePartImgFrame = styled.div`
  width: 70vw;
  display: flex;
  flex-flow: row nowrap;

  > img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const GuidePartConnect = styled.div`
  display: flex;
  justify-content: center;
  padding: ${srem(3)} 0;

  &::after {
    content: '';
    display: block;
    width: 2px;
    height: ${srem(11)};
    background: ${t(t => t.colors.blue)};
  }
`;

export const GuidePart = styled.div<{ iZag: boolean }>`
  display: flex;
  flex-flow: ${ift('iZag', 'row-reverse', 'row')} nowrap;

  > ${GuidePartText} {
    padding-left: ${ift('iZag', '0', t => t.size.maxWidth / 12)}px;
  }

  > ${GuidePartImgFrame} {
    flex-flow: ${ift('iZag', 'row', 'row-reverse')} nowrap;
    padding: ${ift(
      'iZag',
      t => `0 ${t.size.maxWidth / 12}px 0 0`,
      t => `0 0 0 ${t.size.maxWidth / 12}px`,
    )};
  }
`;

/**
 * FAQ section
 */
export const FaqSection = styled(Section)``;

export const FaqHeader = styled.header`
  ${alignCenter}

  color: ${t(t => t.colors.cream)};
  margin-bottom: ${srem(9)};
`;

export const FaqColsGrid = styled.div`
  ${alignCenter}

  ${mq('laptop')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    column-gap: ${srem(4)};
  }
`;

export const FaqRowsGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  align-items: start;
  row-gap: ${srem(4)};

  ${mq('tablet')} {
    padding: 0 ${srem(3)};
  }
`;

export const FaqTile = styled.article`
  background: ${t(t => t.colors.cream)};
  color: ${t(t => t.colors.blue)};
  padding: ${srem(3)};
`;

export const FaqH = styled.h2`
  font-size: ${srem(1)};
  font-family: ${t(t => t.fonts.headings.family)};
`;

export const FaqP = styled.p`
  margin-top: 1rem;
`;
