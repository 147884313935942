import { styled } from 'styled';
import { boxRatio, srem } from 'styled/utils';

export const BlockImage = styled.div<{
  withImage?: string;
  withRatio?: number;
  withMarginStep?: number;
}>`
  width: 100%;
  background: ${({ withImage, theme }) =>
    withImage ? `url('${withImage}') center / cover` : theme.colors.gray[3]};

  ${({ withRatio = 1 }) => boxRatio(withRatio)}

  * + & {
    margin-top: ${({ withMarginStep }) =>
      !withMarginStep ? '0' : srem(withMarginStep)};
  }
`;
