import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React from 'react';
import * as S from './styled';
const addEmailMutation = loader(
  '../../graphql/mutations/addEmailToWaitlist.graphql',
);

interface EmailSignupProps {
  placeholder?: string;
  cta?: string;
  className?: string;
  darkBg?: boolean;
}

const EmailSignup: React.FC<EmailSignupProps> = ({
  className,
  cta,
  darkBg,
  placeholder,
}) => {
  const [addEmail, { data, loading }] = useMutation(addEmailMutation);
  const [emailVal, setEmailVal] = React.useState<string>('');

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailVal(e.target.value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      addEmail({ variables: { email: emailVal } });
    },
    [addEmail, emailVal],
  );

  return (
    <S.Frame useCream={darkBg} className={className} onSubmit={handleSubmit}>
      {!data ? (
        <>
          <S.Input
            useCream={darkBg}
            required
            disabled={loading}
            placeholder={placeholder ?? ''}
            value={emailVal}
            onChange={handleChange}
          />
          <S.Submit useCream={darkBg}>{loading ? 'Sending...' : cta}</S.Submit>
        </>
      ) : (
        <p>Thanks for signing up. We'll email you when we Launch!</p>
      )}
    </S.Frame>
  );
};

export default EmailSignup;
