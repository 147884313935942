import NumberCircle from 'components/NumberCircle';
import Page from 'components/Page';
import { Title } from 'components/Styled';
import useBeta from 'hooks/useBetaGroups';
import React from 'react';
import Media from 'react-media';
import { useRouteMatch } from 'react-router';
import theme from 'styled/theme';
import { lines } from 'utils/elements';
import content from './content';
import * as S from './styled';

interface HomeRouteProps {}

const HomeRoute = () => {
  const match = useRouteMatch<{ beta_slug?: string }>();
  const { matchSlug } = useBeta();
  const betaGroup = React.useMemo(() => matchSlug(match.params?.beta_slug), [
    match.params,
    matchSlug,
  ]);

  // Group FAQs evenly into 3 groups
  const faqGroups = React.useMemo(() => {
    const total = content.faqs.items.length;
    const perCol = Math.ceil(total / 3);
    const groups = Array.from(Array(3)).map((_, i) =>
      content.faqs.items.slice(i * perCol, i * perCol + perCol),
    );
    return groups;
  }, []);

  const signupEl = !betaGroup && (
    <S.HeroEmailSignup
      placeholder="Your email address"
      cta="Get early access"
    />
  );

  return (
    <Page stickyNav betaGroup={betaGroup}>
      <S.Hero>
        <Media query={`(${theme.media.laptop})`}>
          {matches =>
            matches ? (
              <>
                <S.HeroText>
                  {/* Large version */}
                  <S.HeroHalf>
                    <Title>{lines(content.hero.title)}</Title>
                    <S.HeroP>{content.hero.description}</S.HeroP>
                    {signupEl}
                  </S.HeroHalf>
                </S.HeroText>
                <S.HeroImage>
                  <S.HeroImageIlly
                    topBarOffset={Boolean(betaGroup)}
                    withImg="/illys/lines-home-hero.png"
                  />
                </S.HeroImage>
              </>
            ) : (
              <>
                {/* Mobile version */}
                <S.HeroMblTitle>{lines(content.hero.title)}</S.HeroMblTitle>
                <S.HeroImage>
                  <S.HeroImageIlly
                    topBarOffset={Boolean(betaGroup)}
                    withImg="/illys/home-hero-mbl.png"
                  />
                </S.HeroImage>
                <S.HeroText>
                  <S.HeroP>{content.hero.description}</S.HeroP>
                  {signupEl}
                </S.HeroText>
              </>
            )
          }
        </Media>
      </S.Hero>

      <S.CompaniesSection backgroundColor="blue" paddingBottom={13}>
        <S.CompanaiesHeader>
          <Title withSize={5}>{lines(content.companies.title)}</Title>
        </S.CompanaiesHeader>
        <S.CompaniesLogosGrid>
          {content.companies.logos.map((l, i) => (
            <S.CompanyLogo key={`${l}-${i}`} withImg={l} />
          ))}
        </S.CompaniesLogosGrid>
      </S.CompaniesSection>

      <S.ProcessSection
        backgroundColor="cream"
        paddingTop={13}
        paddingBottom={13}
      >
        <S.ProcessGrid>
          <S.ProcessTitle withSize={5}>
            {lines(content.process.title)}
          </S.ProcessTitle>
          <S.ProcessDesc>{content.process.description}</S.ProcessDesc>
          {content.process.tiles.map((t, i) => (
            <S.ProcessTile key={t.part} withPart={t.part}>
              <S.ProcessTileText>
                <NumberCircle>{i + 1}</NumberCircle>
                <p>{t.text}</p>
              </S.ProcessTileText>
              <S.ProcessImg withPart={t.part} />
            </S.ProcessTile>
          ))}
        </S.ProcessGrid>
      </S.ProcessSection>

      <S.FaqSection backgroundColor="blue" paddingTop={12} paddingBottom={12}>
        <S.FaqHeader>
          <Title textCenter>{content.faqs.title}</Title>
        </S.FaqHeader>
        <S.FaqColsGrid>
          <Media query={`(${theme.media.laptop})`}>
            {match =>
              match ? (
                faqGroups.map((fg, i) => (
                  <S.FaqRowsGrid key={i}>
                    {fg.map((faq, j) => (
                      <S.FaqTile key={`faq-${i}-${j}`}>
                        <S.FaqH>{faq.title}</S.FaqH>
                        {faq.body && <S.FaqP>{faq.body}</S.FaqP>}
                      </S.FaqTile>
                    ))}
                  </S.FaqRowsGrid>
                ))
              ) : (
                // Only one column needed on mobile
                <S.FaqRowsGrid>
                  {content.faqs.items.map((faq, j) => (
                    <S.FaqTile key={`faq-${j}`}>
                      <S.FaqH>{faq.title}</S.FaqH>
                      {faq.body && <S.FaqP>{faq.body}</S.FaqP>}
                    </S.FaqTile>
                  ))}
                </S.FaqRowsGrid>
              )
            }
          </Media>
        </S.FaqColsGrid>
      </S.FaqSection>
    </Page>
  );
};

export default HomeRoute;
