import React from 'react';
import * as S from './styled';
import { socialLinks } from 'config/social';

interface MobileNavProps {
  links: NavLinkDescription[];
}

const MobileNav = ({ links }: MobileNavProps) => {
  return (
    <S.MobileNavOverlay>
      <S.MobileNavHeader>
        <S.NavLogo isLight to="/" />
      </S.MobileNavHeader>
      <S.MobileNavLinks>
        {links.map((l, i) =>
          l.path ? (
            <S.MobileNavLink key={i} to={l.path} exact>
              {l.label}
            </S.MobileNavLink>
          ) : l.url ? (
            <S.MobileNavAnchor key={i} href={l.url}>
              {l.label}
            </S.MobileNavAnchor>
          ) : null,
        )}
      </S.MobileNavLinks>
      <S.MobileNavFooter>
        {socialLinks.map(l => (
          <a href={l.href} target="_blank" rel="noopener noreferrer">
            {l.label}
          </a>
        ))}
      </S.MobileNavFooter>
    </S.MobileNavOverlay>
  );
};

export default MobileNav;
