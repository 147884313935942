import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

const getBetaAccessGroupsQuery = loader(
  '../graphql/queries/getBetaAccessGroups.graphql',
);

const useBeta = () => {
  const { data, loading, error } = useQuery<{
    getAllBetaAccessGroups: GqlBetaAccessGroupType[];
  }>(getBetaAccessGroupsQuery);

  const matchSlug = React.useCallback(
    (slug?: string): GqlBetaAccessGroupType | undefined =>
      slug
        ? (data?.getAllBetaAccessGroups ?? []).find(c => c.slug === slug)
        : undefined,
    [data],
  );

  return { groups: data?.getAllBetaAccessGroups, loading, error, matchSlug };
};

export default useBeta;
