import React from 'react';
import { ButtonFrame } from './styled';
import { Link } from 'react-router-dom';

interface ButtonProps {
  // Type modifiers
  submit?: boolean;
  // Link props
  to?: string;
  href?: string;
  // Events
  onClick?: () => void;
  // Display modifiers
  grow?: boolean;
  large?: boolean;
  small?: boolean;
  flat?: boolean;
  caps?: boolean;
  secondary?: boolean;
  // Content
  children?: React.ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  to,
  children,
  href,
  grow,
  large,
  small,
  flat,
  secondary,
  caps,
  submit,
  className,
}) => {
  const type = submit || onClick ? 'button' : to ? Link : href ? 'a' : 'div';
  const mappedProps: { [prop: string]: any } = {
    to,
    href,
    as: type,
  };

  if (submit) {
    mappedProps.type = 'submit';
  }

  return (
    <ButtonFrame
      className={className}
      isFlat={flat}
      flexGrow={grow}
      isLarge={large}
      isSmall={small}
      isSecondary={secondary}
      isCaps={caps}
      as={type}
      {...mappedProps}
    >
      {children}
    </ButtonFrame>
  );
};

export default Button;
