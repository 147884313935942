import { styled } from 'styled';
import Button from 'components/Button';
import { t, srem } from 'styled/utils';
import { keyframes } from 'styled-components';

const animateIn = keyframes`
  from {
    transform: translateY(-100%);
  }
`;

export const Frame = styled.section`
  position: fixed;
  z-index: ${t(t => t.stack.nav + 100)};
  animation: ${animateIn} 500ms 1 both ease;
  background: ${t(t => t.colors.blue)};
  color: ${t(t => t.colors.cream)};
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: ${srem(8)};
`;

export const CTA = styled(Button)`
  background: ${t(t => t.colors.cream)};
  color: ${t(t => t.colors.blue)};
  margin-left: ${srem(4)};
`;
