import faqs from 'content/faqs';
import React from 'react';

const homepageContent = {
  hero: {
    title: ['Your Job Search.', 'Automated.'],
    description:
      'Use Frontier to instantly apply to hundreds of work-at-home jobs. Save countless hours searching for and applying to jobs.',
  },

  companies: {
    title: ['Great companies', 'hiring work-at-home'],
    logos: [
      'sykes.svg',
      'williams.svg',
      'jpm.svg',
      'dell.svg',
      'unitedhealth.svg',
      'amazon.svg',
      'salesforce.svg',
      'hilton.svg',
      'apple.svg',
      'walmart.svg',
      'vip-kid.svg',
      'uhaul.svg',
    ],
  },

  process: {
    title: ['One application.', 'Hundreds of jobs.'],
    description:
      'Our technology curates similar jobs and allows you to apply to all of them with a single application.',
    tiles: [
      { part: 'a', text: 'You write one application' },
      { part: 'b', text: 'for one role' },
      { part: 'c', text: 'and we automatically send it to hundreds of jobs' },
    ],
  },

  guide: {
    title: ['How you use Frontier'],
    parts: [
      {
        title: [
          <>
            Discover <strong>new roles.</strong>
          </>,
          'Find one you like.',
        ],
        img: 'guide-1.svg',
      },
      {
        title: ['Learn about the role.', 'See if it’s right for you.'],
        img: 'guide-2.svg',
      },
      {
        title: ['Apply once to all the jobs in the role.'],
        img: 'guide-3.svg',
      },
      {
        title: ['Wait for confirmations, responses and interviews!'],
        img: 'guide-4.svg',
      },
    ],
  },

  faqs: {
    title: 'FAQs',
    items: faqs.faqs,
  },
};

export type HomeContentType = typeof homepageContent;
export default homepageContent as HomeContentType;
