import Footer from 'components/Footer';
import Loading from 'components/Loading';
import Nav from 'components/Nav';
import React from 'react';
import { PageContent, PageFrame } from './styled';
import { useLocation } from 'react-router';
import BetaBar from 'components/BetaBar';
import { Theme } from 'styled';

// The <Page /> is intended to wrap any view that can be considered a "Page". It
// includes things like the Nav and other global elements required by most pages

interface PageProps {
  children: React.ReactNode;
  stickyNav?: boolean;
  betaGroup?: GqlBetaAccessGroupType;
  className?: string;
  dark?: boolean;
  center?: boolean;
  // When loading is true, a full frame loading animation will replace the
  // content until loading has completed
  loading?: boolean;
}

const darkColor: keyof Theme['colors'] = 'blue';
const lightColor: keyof Theme['colors'] = 'cream';

const Page: React.FC<PageProps> = ({
  stickyNav = true,
  loading,
  children,
  className,
  betaGroup,
  dark,
  center,
}) => {
  const location = useLocation();
  // On every page change
  React.useEffect(() => {
    // Ensure page scroll is reset
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <PageFrame className={className}>
      {betaGroup && <BetaBar group={betaGroup} />}
      <Nav
        dark={dark}
        offset={betaGroup ? '64px' : undefined}
        fixed={stickyNav}
      />
      <PageContent 
        withTextColor={dark ? lightColor : undefined}
        withColor={dark ? darkColor : undefined}
        alignCenter={center}>
        {loading ? <Loading fullscreen /> : <>{children}</>}
      </PageContent>
      <Footer />
    </PageFrame>
  );
};

export default Page;

// Export convenient child elements for flexible composition
export { PageCenter as Center, PageSection as Section } from './styled';
