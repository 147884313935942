import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';
import reset from 'styled-reset';
import { css } from './styled';

const webfonts = css`
  @font-face {
    font-family: 'gt-america';
    src: url('/fonts/gtamerica/GTAmerica-MediumItalic.woff2') format('woff2'),
      url('/fonts/gtamerica/GTAmerica-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'gt-america';
    src: url('/fonts/gtamerica/GTAmerica-Medium.woff2') format('woff2'),
      url('/fonts/gtamerica/GTAmerica-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'gt-america';
    src: url('/fonts/gtamerica/GTAmerica-Regular.woff2') format('woff2'),
      url('/fonts/gtamerica/GTAmerica-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'gt-america';
    src: url('/fonts/gtamerica/GTAmerica-RegularItalic.woff2') format('woff2'),
      url('/fonts/gtamerica/GTAmerica-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'gt-america-extended';
    src: url('/fonts/gtamerica/hinted-GTAmerica-ExtendedRegular.woff2')
        format('woff2'),
      url('/fonts/gtamerica/hinted-GTAmerica-ExtendedRegular.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'gt-america-extended';
    src: url('/fonts/gtamerica/hinted-GTAmerica-ExtendedMedium.woff2')
        format('woff2'),
      url('/fonts/gtamerica/hinted-GTAmerica-ExtendedMedium.woff')
        format('woff');
    font-weight: 500;
    font-style: normal;
  }

  /* Define the "system" font family */
  @font-face {
    font-family: system;
    font-style: normal;
    font-weight: 300;
    src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
      local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'),
      local('Roboto-Light'), local('DroidSans'), local('Tahoma');
  }
`;

// These styles will be defined for the entire project. Only put something here
// if it's absolutely a global need. If it isn't, make a styled-component
// instance and scope the styles to where they are needed.
const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  ${reset}

  ${webfonts}

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *::before, *::after { box-sizing: border-box; }

  html {
    font-family: ${({ theme }) => theme.fonts.alpha.family};
    font-size: ${({ theme }) => (theme.baseFontSize / 16) * 100}%;
    color: white;
  }

  body {
    color: ${({ theme }) => theme.colors.black};
    background: white;
    line-height: ${({ theme }) => theme.sizeScale};
  }

  a {
    color: ${({ theme }) => theme.colors.black};
  }

  /* Global typography */
  p {
    line-height: 1.5;
  }
`;

export default GlobalStyles;
