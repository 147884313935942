import Page from 'components/Page';
import React from 'react';

interface ForCompaniesRouteProps {}

const ForCompaniesRoute = () => {
  return <Page>
    ForCompanies
  </Page>;
};

export default ForCompaniesRoute;
