const easing = {
  easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
};

// These will be used to compose media queries
const supportedScreens = {
  mobileS: 320,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
  hd: 2560,
};

// Map the keys of supportedScreens to a new type
// see: https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types
type MediaQueries = { [P in keyof typeof supportedScreens]: string };

// Defuine the mediaquery strings
const mediaQueries: MediaQueries = Object.entries(supportedScreens).reduce(
  (o, [k, v]) => ({ ...o, [k]: `min-width: ${v}px` }),
  {} as MediaQueries,
);

// This will be used to configure the modular scale
// For more context -> https://www.modularscale.com/?1&em&1.3333
// Max's ~scale 1.189
const sizeScale = 1.18888888;
// const sizeScale = 1.3333333;

const theme = {
  sizeScale,
  easing,
  media: mediaQueries,
  // The full `@media screen and (%MQ%)`
  $mq: Object.keys(mediaQueries).reduce(
    (o, mq) => ({
      ...o,
      [mq]: `@media screen and (${mediaQueries[mq as keyof MediaQueries]})`,
    }),
    {},
  ) as MediaQueries,

  // Control the radius of borders
  radius: {
    button: 4,
    input: 4,
  },

  // Define layers, these will be used as z-index values to manage the UI
  // element stack, and avoid overlapping bugs
  stack: {
    static: 10000, // For static elements sticky navs and other "static" elements
    nav: 13000,
    loading: 14000,
    modal: 15000, // For modal elements (or anything that should be above everything else)
  },

  // Color library and helpers can be defined here
  // Color groups go from dark ==> light
  colors: {
    white: '#fff',
    black: '#011B39',
    gray: ['#333333', '#4F4F4F', '#828282', '#BDBDBD', '#E0E0E0', '#F2F2F2'],
    blue: '#001D67',
    blueGray: '#95A4B5',
    cream: '#FAF5E5',
    green: ['#219653', '#27AE60', '#6FCF97'],
    pastel: {
      blue: '#ECFAFF',
      green: '#E2FFEE',
      gray: '#F2F2F2',
    },
  },

  fonts: {
    alpha: {
      family: 'gt-america, system',
      weights: {
        normal: 'normal',
        medium: 500,
      },
    },
    headings: {
      family: 'gt-america-extended, system',
      weights: {
        normal: 'normal',
        medium: 500,
      },
    },
  },

  // Shadow styles
  shadows: {
    close: {
      subtleBlue: '0px 2px 2px rgba(67, 104, 155, 0.20)',
      subtleGray: '0px 2px 2px rgba(40, 72, 116, 0.19)',
    },
    medium: {
      gray: '0px 19px 30px rgba(40, 72, 116, 0.2)',
      blueGray: '0px 19px 25px rgba(17, 101, 200, 0.15)',
    },
    far: {},
  },

  // Define the base font size
  baseFontSize: 16,

  // Sizes
  size: {
    // This maps to the maximum width of the 'page'
    maxWidth: 1312,
    // maxWidth: 1440,
  },

  // The below helpers give us direct access to the modular scale for consistent
  // scaling and typography
  ms: (f: number): number => Math.pow(sizeScale, f),
  mspx: (f: number): number => theme.ms(f) * theme.baseFontSize,
  msrem: (f: number): string => `${theme.ms(f)}rem`,
};

// Define our theme type with the theme object
export type Theme = typeof theme;

export default theme;
