import React from 'react';

const aboutContent = {
  top: {
    left: {
      title: 'Democratizing access to the digital economy',
      icon: 'steps-cream.svg',
      text: [
        'At Frontier we’re on a mission to connect humans with employment opportunities regardless of their location in the world. We believe remote work will give millions of people the chance to participate and thrive in the global economy.',
      ],
    },
    right: {
      text: [
        'Over the last few centuries our place of work has evolved - from the fields, to the factories and lastly to the office. Over the next decade we will see businesses embrace work-at-home jobs in a way they previously have not. This shift will not only change how we work but will also play a key role in bringing equal access to opportunity.',
      ],
    },
  },

  bottom: {
    left: {
      title: 'Team',
      icon: 'balls-pyramid-cream.svg',
      text: [
        'We’re a remote team, building platforms and services to connect job seekers with companies. ',
        <>
          Excited by our mission?{' '}
          <a href="mailto:hello@applyfrontier.com">Please get in touch</a>.
        </>,
      ],
    },
    right: {
      title: 'Backing',
      text: [
        'Our company is backed by leading venture capital funds across San Francisco, New York and London. We are building an enduring and ambitious company that will deliver meaningful value to customers, employees and society. ',
      ],
      icon: 'balls-offset-pyramid-cream.svg',
    },
  },
};

export default aboutContent as typeof aboutContent;
