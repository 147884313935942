import { Section } from 'components/Page';
import { Title } from 'components/Styled';
import { styled } from 'styled';
import { ift, srem, t, mq } from 'styled/utils';

export const AboutGrid = styled(Section)`
  width: 100%;
  max-width: ${t(t => t.size.maxWidth)}px;
  margin: ${srem(11)} auto;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);

  ${mq('tablet')} {
    column-gap: ${srem(4)};
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Span = styled.div`
  ${mq('tablet')} {
    grid-column: 1 / span 2;
  }
`;

export const Block = styled.div<{ padTop?: boolean }>`
  padding-top: ${ift('padTop', t => t.msrem(9), '0')};

  & + & {
    margin-top: ${({ theme: t, padTop }) => (padTop ? '0' : t.msrem(3))};
  }

  ${mq('tablet')} {
    & + & {
      margin: 0;
    }
  }
`;

export const BlockH = styled(Title)`
  max-width: 640px;
  margin-bottom: ${srem(2)};
`;

export const BlockIcon = styled.div<{ withImg: string }>`
  background: url('/icons/${({ withImg }) => withImg}') center / 100% auto;
  width: 88px;
  height: 88px;
  margin-bottom: ${srem(1)};
`;

export const BlockText = styled.div`
  p {
    font-size: ${srem(1)};
  }

  p + p {
    margin-top: 1em;
  }
`;
