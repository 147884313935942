import { RouteProps } from 'react-router';
import HomeRoute from 'routes/Home';
import AboutRoute from 'routes/About';
import ForCompaniesRoute from 'routes/ForCompanies';

export interface RouteDescription extends RouteProps {
  name: string;
}

const appRoutes: RouteDescription[] = [
  {
    name: 'home',
    path: '/',
    exact: true,
    component: HomeRoute,
  },
  {
    name: 'about',
    path: '/about',
    exact: true,
    component: AboutRoute,
  },
  {
    name: 'for-companies',
    path: '/for-companies',
    exact: true,
    component: ForCompaniesRoute,
  },
];

export default appRoutes;
