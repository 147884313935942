import Page from 'components/Page';
import React from 'react';
import { useRouteMatch } from 'react-router';
import useBeta from 'hooks/useBetaGroups';
import * as S from './styled';
import content from './content';
import { lines } from 'utils/elements';

interface AboutRouteProps {}

const AboutRoute = () => {
  const match = useRouteMatch<{ beta_slug?: string }>();
  const { matchSlug } = useBeta();
  const betaGroup = React.useMemo(() => matchSlug(match.params?.beta_slug), [
    match.params,
    matchSlug,
  ]);

  return (
    <Page center stickyNav dark betaGroup={betaGroup}>
      <S.AboutGrid>
        <S.Span>
          <S.BlockIcon withImg={content.top.left.icon} />
          <S.BlockH marginBottom>{content.top.left.title}</S.BlockH>
        </S.Span>
        <S.Block>
          <S.BlockText>{lines(content.top.left.text, 'p')}</S.BlockText>
        </S.Block>
        <S.Block>
          <S.BlockText>{lines(content.top.right.text, 'p')}</S.BlockText>
        </S.Block>
        <S.Block padTop>
          <S.BlockIcon withImg={content.bottom.left.icon} />
          <S.BlockH marginBottom>{content.bottom.left.title}</S.BlockH>
          <S.BlockText>{lines(content.bottom.left.text, 'p')}</S.BlockText>
        </S.Block>
        <S.Block padTop>
          <S.BlockIcon withImg={content.bottom.right.icon} />
          <S.BlockH marginBottom>{content.bottom.right.title}</S.BlockH>
          <S.BlockText>{lines(content.bottom.right.text, 'p')}</S.BlockText>
        </S.Block>
      </S.AboutGrid>
    </Page>
  );
};

export default AboutRoute;
