export const socialLinks = [
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/34896564',
  },
  {
    label: 'Instagram',
    href: 'https://instagram.com/joinfrontier',
  },
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/joinfrontier',
  },
];
