import React from 'react';
import * as S from './styled';
import { socialLinks } from 'config/social';

interface FooterProps {}

const content = {
  title: 'Would you like to keep up with the latest Frontier developments?',
  navigation: {
    title: 'Navigation',
    links: [
      {
        label: 'Home',
        href: '/',
      },
      {
        label: 'About',
        href: '/about',
      },
      // {
      //   label: 'For Companies',
      //   href: '/for-companies',
      // },
      {
        label: 'Terms of Service',
        href: '/terms_of_service.pdf',
      },
      {
        label: 'Privacy policy',
        href: '/privacy_policy.pdf',
      },
    ],
  },
  social: {
    title: 'Social',
    links: socialLinks,
  },
  contact: {
    title: 'Contact',
    links: [
      {
        label: 'hello@applyfrontier.com',
        href: 'mailto:hello@applyfrontier.com',
      },
    ],
  },
};

const Footer: React.FC<FooterProps> = () => {
  return (
    <S.Frame>
      <S.FooterGrid>
        <S.MailingListTitle>{content.title}</S.MailingListTitle>
        <S.MailingListJoin>
          <S.FooterEmailSignup
            darkBg
            cta="Sign Up"
            placeholder="Your email address"
          />
        </S.MailingListJoin>

        <S.LinksSection inArea="nav">
          <S.SectionTitle>{content.navigation.title}</S.SectionTitle>
          <S.NavLinks>
            {content.navigation.links.map((l, i) => (
              <S.NavLink key={i} href={l.href}>
                {l.label}
              </S.NavLink>
            ))}
          </S.NavLinks>
        </S.LinksSection>

        <S.LinksSection inArea="social">
          <S.SectionTitle>{content.social.title}</S.SectionTitle>
          <S.NavLinks>
            {content.social.links.map((l, i) => (
              <S.NavLink key={i} href={l.href}>
                {l.label}
              </S.NavLink>
            ))}
          </S.NavLinks>
        </S.LinksSection>

        <S.LinksSection inArea="contact">
          <S.SectionTitle>{content.contact.title}</S.SectionTitle>
          {content.contact.links.map((l, i) => (
            <S.NavLink key={i} href={l.href}>
              {l.label}
            </S.NavLink>
          ))}
        </S.LinksSection>
      </S.FooterGrid>
    </S.Frame>
  );
};

export default Footer;
