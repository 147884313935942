import { styled } from 'styled';
import { srem, t, mq } from 'styled/utils';
import { css } from 'styled-components';

export const Frame = styled.form<{
  useCream?: boolean;
}>`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  ${mq('laptop')} {
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: stretch;
  }

  // For the thank you message
  p {
    font-size: ${srem(1)};
    color: ${({ theme: t, useCream }) =>
      useCream ? t.colors.cream : t.colors.blue};
  }
`;

const fontStyles = css`
  font-family: ${t(t => t.fonts.alpha.family)};
  font-weight: normal;
  font-size: ${srem(1)};
`;

export const Input = styled.input.attrs({ type: 'email' })<{
  useCream?: boolean;
}>`
  ${fontStyles}
  flex: 1 0 auto;
  color: ${({ theme: t, useCream }) =>
    useCream ? t.colors.cream : t.colors.black};
  border: 2px solid
    ${({ theme: t, useCream }) => (useCream ? t.colors.cream : t.colors.blue)};
  background: ${({ useCream }) => (useCream ? 'transparent' : 'whit')};
  padding: ${srem(-1)} ${srem(1)};
  border-radius: ${t(t => t.radius.button)}px;
  margin: 0;
  text-align: center;

  ${mq('laptop')} {
    text-align: left;
    border-radius: ${t(t => `${t.radius.button}px 0 0 ${t.radius.button}px`)};
  }
`;

export const Submit = styled.button.attrs({ type: 'submit' })<{
  useCream?: boolean;
}>`
  ${fontStyles}
  flex: 0 0 auto;
  display: block;
  border: none;
  background: ${({ theme: t, useCream }) =>
    useCream ? t.colors.cream : t.colors.blue};
  color: ${({ theme: t, useCream }) =>
    useCream ? t.colors.black : t.colors.cream};
  padding: 1rem ${srem(4)};
  margin-top: ${srem(-2)};
  cursor: pointer;
  border-radius: ${t(t => t.radius.button)}px;

  ${mq('laptop')} {
    margin: 0;
    border-radius: ${t(t => `0 ${t.radius.button}px ${t.radius.button}px 0`)};
  }
`;
