import { css } from './styled';
import { Theme } from './theme';

type ThemeUtil<A extends any, R = string> = (
  arg: A,
) => (theme: { theme: Theme }) => R;

// Add padding to the element until the screen width is > the passed width. This
// is useful for adding margin between the viewport edga and the content on
// small devices
export const paddingUntilWidth = (maxWidth: number, exp: number = 3) => css`
  @media screen and (max-width: ${maxWidth}px) {
    padding-left: ${({ theme }) => theme.msrem(exp)};
    padding-right: ${({ theme }) => theme.msrem(exp)};
  }
`;

// Get device mediaquery rule from the available devices object
export const device: ThemeUtil<keyof Theme['media']> = device => ({ theme }) =>
  theme.media[device];

export const mq: ThemeUtil<keyof Theme['media']> = device => ({ theme: t }) =>
  t.$mq[device];

export const srem: ThemeUtil<number> = exp => ({ theme }) => theme.msrem(exp);
export const ms: ThemeUtil<number> = exp => ({ theme }) =>
  String(theme.ms(exp));

type ThemeShorthand = (t: Theme) => number | string | boolean;

// Shorthand theme object accessor
// Usage:
// background-color: ${t(t => t.colors.red)};
export const t: ThemeUtil<ThemeShorthand> = fn => ({ theme }) => {
  const o = fn(theme);
  // Catch falsey values to avoid junk in the CSS
  if (!o) {
    return '';
  }
  // Forced string as the callback can return anything
  return String(o);
};

// If boolean value test with theme access and fallback value
// Usage:
// font-size: ${ift('bigText', t => t.msrem(2), t => t.msrem(1))};
// width: ${ift('autoWidth', 'auto')};
type IfThenStyled = (
  predicate: string,
  value: ThemeShorthand | string,
  defaultVal?: ThemeShorthand | string,
) => (theme: any) => string;
export const ift: IfThenStyled = (p, v, d) => props =>
  Boolean(props?.[p])
    ? // Resolve true value
      typeof v === 'string'
      ? v
      : String(v(props.theme))
    : d
    ? // Resolve false value
      typeof d === 'string'
      ? d
      : String(d(props.theme))
    : '';

// Make a box hight a ratio of the width with ::after
// Ratio between 0-1
export const boxRatio = (
  ratio: number = 1,
  direction: string = 'top',
): string => `
  &::after {
    content: '';
    display: block;
    width: 1px;
    padding-${direction}: ${ratio * 100}%;
  }
`;
