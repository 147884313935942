import { styled } from 'styled';
import EmailSignup from 'components/EmailSignup';
import { Subtitle } from 'components/Styled';
import { device, srem, t, paddingUntilWidth, mq } from 'styled/utils';

export const Frame = styled.footer`
  background: ${t(t => t.colors.black)};
  color: ${t(t => t.colors.cream)};
  width: 100%;
  padding: ${srem(11)} 0;
`;

export const FooterGrid = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${t(t => t.size.maxWidth)}px;
  margin: 0 auto;

  ${({ theme }) => paddingUntilWidth(theme.size.maxWidth, 5)};

  @media screen and (${device('laptop')}) {
    display: grid;
    grid-template:
      'title title title join join join' auto
      'nav nav social social contact contact' 1fr / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: ${srem(11)} ${srem(4)};
  }
`;

export const MailingListTitle = styled(Subtitle)`
  ${mq('laptop')} {
    grid-area: title;
  }
`;

export const MailingListJoin = styled.section`
  ${mq('laptop')} {
    grid-area: join;
  }
`;

export const LinksSection = styled.section<{
  inArea: 'nav' | 'social' | 'contact';
}>`
  grid-area: ${({ inArea }) => inArea};
`;

export const SectionTitle = styled(Subtitle)`
  margin-top: ${srem(5)};
  font-size: ${srem(2)};

  ${mq('laptop')} {
    margin-top: ${srem(3)};
  }
`;

export const NavLinks = styled.nav``;

export const NavLink = styled.a`
  margin-top: ${srem(-1)};
  display: block;
  text-decoration: none;
  font-size: ${srem(1)};
  color: ${t(t => t.colors.cream)} !important;

  ${mq('laptop')} {
    margin-top: 1em;
    font-size: ${srem(2)};
  }
`;

export const FooterEmailSignup = styled(EmailSignup)`
  margin-top: ${srem(3)};

  ${mq('laptop')} {
    padding-right: ${srem(8)};
  }
`;
