import { styled, css, Theme } from 'styled';
import { t } from 'styled/utils';

export const Layer = styled.div`
  width: 100%;
  height: 2px;
  transition: all 600ms ${t(t => t.easing.easeOutExpo)};
  background: ${t(t => t.colors.black)};
  border-radius: 2px;
  transform-origin: 3px center;
`;

interface HamburgerFrameStyledProps {
  asCross?: boolean;
  useColor?: keyof Theme['colors'];
}

const frameCrossMod = css`
  & > ${Layer}:nth-child(1) {
    transform: rotate(45deg);
  }
  & > ${Layer}:nth-child(2) {
    width: 0;
    opacity: 0;
  }
  & > ${Layer}:nth-child(3) {
    transform: rotate(-45deg);
  }
`;

export const Frame = styled.div<HamburgerFrameStyledProps>`
  position: relative;
  transition: all 300ms ease;
  z-index: ${t(t => t.stack.nav)};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 26px;
  height: 16px;
  ${({ asCross }) => asCross && frameCrossMod}

  ${Layer} {
    ${({ useColor, theme }) =>
      useColor && `background: ${theme.colors[useColor]};`}
  }
`;
