import React from 'react';
import { styled } from 'styled';

const Line = styled.span`
  display: block;
`;

export const lines = (
  l: (string | React.ReactNode)[],
  tag?: 'p',
): React.ReactNode[] =>
  l.map((line, i) => (
    <Line as={tag} key={i}>
      {line}
    </Line>
  ));
