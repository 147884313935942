type FaqObject = {
  title: string;
  body: string[];
};

const faqsContent = {
  faqs: [
    {
      title: 'How is this service free?',
      body:
        'Frontier’s platform uses automation technology to find and apply to jobs for you without human intervention. This means it’s really cheap for us to help thousands of people find jobs. Companies trying to hire you directly through Frontier are charged.',
    },
    {
      title:
        'Why should I use Frontier?',
      body:
        'Frontier applies to hundreds of work-at-home jobs for you in seconds. If you hate spending hours searching for jobs and filling in countless application forms, then you’ll love Frontier. We use AI to find the jobs that suit your requirements, schedule and experience. Our technology then automatically applies to those jobs for you - it’s not magic but it’s pretty close!',
    },
    { title: 'How long will it take to find a job with Frontier?', body: 'In minutes! After you create an account and tell us about yourself, Frontier instantly finds jobs for you that you can do from your home. Our AI then applies to those jobs in a matter of minutes. We can’t control how long it takes for companies to get back to you but you’ll hear from them via your email inbox.' },
    { title: 'Do companies know that I used Frontier to apply?', body: 'No, companies will not know that you used Frontier to fill in their application forms.' },
    { title: 'What kind of jobs does Frontier have?', body: 'We focus on work-at-home jobs. We have thousands of jobs suitable for any age, skill type and work experience. If you can’t find a job that suits you - email us here: help@applyfrontier.com' },
    { title: 'How is Frontier different to a job board?', body: 'We’re pretty different! Job boards just show you jobs that you can apply for. The problem is you need to know what you’re looking for and then you need to apply to each individual job. We want to save you time by recommending jobs that you are qualified for and then applying to them for you.' },
    { title: 'How do I contact Frontier?', body: 'Please email us here: hello@applyfrontier.com' },
    { title: 'I’m a company that wants to hire work-at-home professionals. Can Frontier help?', body: 'Yes we can help you hire from our network of work-at-home professionals. Please email us at hello@applyfrontier.com.' },
  ],
};

export type FaqContentType = typeof faqsContent;
export default faqsContent as FaqContentType;
