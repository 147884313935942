import { styled, Theme } from 'styled';
import { ift, mq, paddingUntilWidth, t } from 'styled/utils';

export const PageFrame = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%;
`;

export const PageContent = styled.div<{
  alignCenter?: boolean;
  withColor?: keyof Theme['colors'];
  withTextColor?: keyof Theme['colors'];
}>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: auto;
  min-height: 100vh;
  width: 100%;
  align-items: ${ift('alignCenter', 'center', 'flex-start')};
  justify-content: ${ift('alignCenter', 'center', 'flex-start')};

  background: ${({ theme: t, withColor }) =>
    withColor ? t.colors[withColor] : 'white'};
  color: ${({ withTextColor, theme: t }) =>
    withTextColor ? t.colors[withTextColor] : t.colors.black};
  transition: all 400ms ease;

  a {
    color: ${({ withTextColor, theme: t }) =>
      withTextColor ? t.colors[withTextColor] : t.colors.black};
  }
`;

type PageCenterStyledProps = {
  alignCenter?: boolean;
  flexRow?: boolean;
  spaceBetween?: boolean;
};
export const PageCenter = styled.div<PageCenterStyledProps>`
  width: 100%;
  max-width: ${t(t => t.size.maxWidth)}px;
  margin: 0 auto;

  display: flex;
  flex-flow: ${ift('flexRow', 'row', 'column')} nowrap;
  align-items: ${ift('alignCenter', 'center', 'flex-start')};
  justify-content: ${ift('spaceBetween', 'space-between', 'flex-start')};
  position: relative;
`;

// For making full width sections with padding etc
interface PageSectionStyledProps {
  backgroundColor?: keyof Theme['colors'];
  pastelBackgroundColor?: keyof Theme['colors']['pastel'];
  paddingBottom?: number;
  paddingTop?: number;
  alignCenter?: boolean;
  navPadding?: boolean;
}

export const PageSection = styled.section<PageSectionStyledProps>`
  width: 100%;
  padding: ${t(t => t.msrem(3))} 0;
  ${({ theme, paddingBottom, paddingTop }) => `
    ${
      paddingBottom
        ? `padding-bottom: ${theme.msrem(paddingBottom - paddingBottom / 3)}`
        : ''
    };
    ${
      paddingTop
        ? `padding-top: ${theme.msrem(paddingTop - paddingTop / 3)}`
        : ''
    };
    `}
  ${ift('navPadding', t => `padding-top: calc(134px + ${t.msrem(5)})`, '')};
  display: flex;
  flex-flow: column nowrap;
  align-items: ${ift('alignCenter', 'center', 'flex-start')};
  background-color: ${({ backgroundColor, pastelBackgroundColor, theme }) =>
    pastelBackgroundColor
      ? theme.colors.pastel[pastelBackgroundColor]
      : backgroundColor
      ? theme.colors[backgroundColor]
      : 'transparent'};

  ${({ theme }) => paddingUntilWidth(theme.size.maxWidth)};

  ${mq('tablet')} {
    padding: ${t(t => t.msrem(5))} 0;
    ${({ theme, paddingBottom, paddingTop }) => `
    ${paddingBottom ? `padding-bottom: ${theme.msrem(paddingBottom)}` : ''};
    ${paddingTop ? `padding-top: ${theme.msrem(paddingTop)}` : ''};
    `}
  }
`;
