import { styled } from 'styled';
import { srem, t } from 'styled/utils';

export const Circle = styled.span`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${srem(4)};
  font-family: ${t(t => t.fonts.headings.family)};
  width: ${srem(7)};
  height: ${srem(7)};
  border-radius: 50%;
  color: ${t(t => t.colors.cream)};
  background: ${t(t => t.colors.blue)};
`;
