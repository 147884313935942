import React from 'react';
import * as S from './styled';
import { Theme } from 'styled';

interface HamburgerProps {
  onClick?: () => void;
  shape?: 'x';
  color?: keyof Theme['colors'];
}

const Hamburger = ({ shape, onClick, color }: HamburgerProps) => (
  <S.Frame useColor={color} asCross={shape === 'x'} onClick={onClick}>
    <S.Layer />
    <S.Layer />
    <S.Layer />
  </S.Frame>
);

export default Hamburger;
