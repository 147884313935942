import { Center } from 'components/Page';
import React from 'react';
import * as S from './styled';
import Media from 'react-media';
import theme from 'styled/theme';
import Hamburger from 'components/Hamburger';
import MobileNav from './MobileNav';

interface NavProps {
  fixed?: boolean;
  offset?: string;
  dark?: boolean;
}

const Nav: React.FC<NavProps> = ({ offset, fixed, dark }) => {
  // Is the mobile nav showing?
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const toggleMobileNav = React.useCallback(
    () => setShowMobileNav(b => !b),
    [],
  );

  // Which links will be visible
  const navLinks: NavLinkDescription[] = [
    {
      label: 'About',
      path: '/about',
    },
    // {
    //   label: 'For Companies',
    //   path: '/for-companies',
    // },
    {
      label: (
        <S.NavButton small flat caps>
          Sign In
        </S.NavButton>
      ),
      url: `${process.env.REACT_APP_JOBS_URL}/signin`,
    },
  ];

  const mobileNavLinks: NavLinkDescription[] = [
    { label: 'Home', path: '/' },
    {
      label: 'About',
      path: '/about',
    },
    {
      label: 'Sign In',
      url: `${process.env.REACT_APP_JOBS_URL}/signin`,
    },
  ];

  const [showBackground, setShowBackground] = React.useState<boolean>(false);

  const scrollListner = React.useCallback(
    (e: Event) => {
      if (window.scrollY > 60 && !showBackground) {
        setShowBackground(true);
      }
      if (window.scrollY <= 60 && showBackground) {
        setShowBackground(false);
      }
    },
    [showBackground],
  );

  React.useEffect(() => {
    document.addEventListener('scroll', scrollListner, false);
    return () => document.removeEventListener('scroll', scrollListner, false);
  }, [scrollListner]);

  return (
    <S.NavFrame
      withBackground={showBackground}
      withOffset={offset}
      isFixed={fixed}
      isLight={dark}
    >
      <Center spaceBetween alignCenter flexRow>
        <S.NavLogo isLight={dark} to="/" />
        <Media query={`(${theme.media.tablet})`}>
          {match =>
            match ? (
              <S.NavLinks isLight={dark}>
                {navLinks.map(nl =>
                  nl.path ? (
                    <S.NavLink key={nl.path} to={nl.path}>
                      {nl.label}
                    </S.NavLink>
                  ) : (
                    <S.NavAnchor key={nl.url} href={nl.url}>
                      {nl.label}
                    </S.NavAnchor>
                  ),
                )}
              </S.NavLinks>
            ) : (
              <>
                {showMobileNav && (
                  <MobileNav
                    // Add "Home" to the mobile nav
                    links={mobileNavLinks}
                  />
                )}
                <Hamburger
                  color={showMobileNav || dark ? 'white' : undefined}
                  shape={showMobileNav ? 'x' : undefined}
                  onClick={toggleMobileNav}
                />
              </>
            )
          }
        </Media>
      </Center>
    </S.NavFrame>
  );
};

export default Nav;
