import { styled } from 'styled';
import { device, ift, srem, t } from 'styled/utils';

interface TitleStyledProps {
  textCenter?: boolean;
  textBold?: boolean;
  marginBottom?: boolean;
  alphaFont?: boolean;
  withSize?: number;
}

export const Title = styled.h1<TitleStyledProps>`
  font-family: ${t(t => t.fonts.headings.family)};
  font-style: normal;
  font-weight: ${ift(
    'textBold',
    t => t.fonts.alpha.weights.medium,
    t => t.fonts.alpha.weights.normal,
  )};
  line-height: ${t(t => t.sizeScale)};
  text-align: ${ift('textCenter', 'center', 'auto')};
  font-size: ${({ withSize, theme }) =>
    withSize ? theme.msrem(withSize - 2) : theme.msrem(4)};
  margin-bottom: ${ift('marginBottom', '1rem', '0')};

  @media screen and (${device('tablet')}) {
    font-size: ${({ withSize, theme }) =>
      withSize ? theme.msrem(withSize) : theme.msrem(6)};
    margin-bottom: ${ift('marginBottom', '1em', '0')};
  }
`;

export const Subtitle = styled.h2<TitleStyledProps>`
  font-family: ${ift(
    'alphaFont',
    t => t.fonts.alpha.family,
    t => t.fonts.headings.family,
  )};
  font-style: normal;
  font-weight: normal;
  line-height: 48px;
  text-align: ${ift('textCenter', 'center', 'left')};
  font-size: ${srem(2)};
`;
