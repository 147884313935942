import React, { Component } from 'react';
import ApolloClient from 'apollo-boost';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import GlobalStyles from 'styled/globals';
import theme from 'styled/theme';
import appRoutes from './routes';
import HomeRoute from 'routes/Home';

interface RootProps {}
interface RootState {}

const gqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT;

// The root component is the entry component for the application. Routes and top
// level state should be managed in this component
export default class Root extends Component<RootProps, RootState> {
  render() {
    const graphqlClient = new ApolloClient({
      uri: gqlEndpoint,
    });

    return (
      <ApolloProvider client={graphqlClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <GlobalStyles />
            <Switch>
              {appRoutes.map(r => (
                <Route key={`route-${r.name}`} {...r} />
              ))}
              {/* Setup routes for beta codes */}
              <Route path="/:beta_slug" component={HomeRoute} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    );
  }
}
