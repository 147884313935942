import React from 'react';
import * as S from './styled';

interface BetaBarProps {
  group?: GqlBetaAccessGroupType;
}

const BetaBar: React.FC<BetaBarProps> = ({ group }) =>
  group ? (
    <S.Frame>
      <p>{group.welcome_message ?? `Thanks for joining us from ${group.organization}!`}</p>
      <S.CTA small flat href={`${process.env.REACT_APP_JOBS_URL}/join`}>
        Get Early Access
      </S.CTA>
    </S.Frame>
  ) : null;

export default BetaBar;
