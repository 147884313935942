import { styled } from 'styled';

export const LoadingAnimationFrame = styled.div<{ isFullscreen?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;


  ${({ isFullscreen, theme }) =>
    isFullscreen
      ? `
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${theme.stack.loading};
    width: 100vw;
    height: 100vh;
    background-color: white;
  `
      : ''}
`;

export const LoadingAnimation = styled.div`
  opacity: 0.5;
  width: 50px;
  height: 50px;
  background: no-repeat url('/loading.gif') center / 100% auto;
`;
